import { routerPaths } from './router.path';

export const isPaymentPage = (location: Location) => {
  return location.pathname.includes(encodeURI(routerPaths.purchaseProducts));
};

export const isEmailPage = (location: Location) => {
  return location.pathname.startsWith(routerPaths.changeEmail);
};

export const isVippsReturnPage = (location: Location) => {
  return location.pathname.startsWith(routerPaths.vippsReturnUrl);
};
