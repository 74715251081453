import { FC, useRef } from 'react';
import classNames from 'classnames';
import { SwimlaneType } from 'ContentLayout';

import { SwimlaneItem } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { CssBemHelper } from '@rikstv/play-common/src/utils/css/CssBemHelper';
import { H3, Meta, SubBody, Tag, TertiaryIconButton } from '@rikstv/shared-components';

import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { AssetImageContainer } from '../../assetImage/AssetImageContainer';
import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneItemImageOverlay } from '../../imageOverlays';
import { ImdbBadge } from '../../imdbBadge/ImdbBadge';
import { Pill } from '../../pill/Pill';
import { ProviderImageWrapper } from '../../providerImage';
import { SwimlaneItemProps, SwimlaneSettings } from '../Swimlane.types';
import { getLinkTo } from '../swimlane.utils';

import { ItemWrapper } from './ItemWrapper';

import './StandardItem.scss';

interface OwnProps {
  showImdbRating?: ImdbProps['showImdbRating'];
  featured?: boolean;
  onClick?: () => void;
  preventNavigation?: boolean;
  noMargin?: boolean;
  showDescription?: boolean;
  swimlaneType: SwimlaneType;
  classes?: string;
  showProviderLogos?: boolean;
  contextMenuItems?: ContextMenuItem[];
}

export interface ContextMenuItem {
  icon: () => JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: SwimlaneItem) => void;
  title: string;
  ariaLabel: string;
}

export type StandardItemProps = SwimlaneItemProps & OwnProps;

const bem = new CssBemHelper('swimlane-standard-item');

const StandardItem: FC<StandardItemProps> = ({
  asset,
  swimlaneType,
  featured,
  showImdbRating = asset.programState.type === 'Live' ? 'never' : 'onHover',
  noMargin = false,
  preventNavigation,
  showDescription = false,
  onClick,
  classes = '',
  showProviderLogos = true,
  contextMenuItems,
}) => {
  const renderImageOverlay = swimlaneType !== 'MyList';
  const titleRef = useRef<HTMLHeadingElement>(null);
  const metaRef = useRef<HTMLElement>(null);

  addScrollIfTextOverflow([titleRef, metaRef]);

  return (
    <ItemWrapper
      className={classNames(bem.base, classes, {
        [bem.modifier('featured')]: featured,
        [bem.modifier('no-margin')]: noMargin,
      })}
      linkTo={getLinkTo(asset)}
      preventDefault={preventNavigation}
      onClick={onClick}>
      <AssetImageContainer>
        <StandardImage image={asset.image.url} blackWhite={asset.image.bwFilter} />
        {renderImageOverlay && <SwimlaneItemImageOverlay programState={asset.programState} />}
        <ImdbPill
          imdbRating={asset.imdbRating}
          showImdbRating={showImdbRating}
          alignRight={asset.programState.type === 'Live'}
        />
        {showProviderLogos && <ProviderImageWrapper logos={asset.logos} />}
        {asset.rentalPrice && <PriceTag price={asset.rentalPrice} />}
      </AssetImageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ overflow: 'hidden' }}>
          <H3
            ref={titleRef}
            lookLike="title-4"
            className={bem.element('title')}
            title={titleRef.current?.classList.contains('scrollable') ? asset.title : undefined}>
            {asset.title}
          </H3>
          <Meta ref={metaRef} className={bem.element('meta')}>
            {asset.subtitle}
          </Meta>
        </div>
        {contextMenuItems &&
          contextMenuItems.map((contextMenuItem, idx) => {
            return (
              <TertiaryIconButton
                key={`contextMenuItem-${idx}-${asset.id}`}
                title={contextMenuItem.title}
                aria-label={contextMenuItem.ariaLabel}
                className="swimlane-standard-item__context-menu"
                icon={contextMenuItem.icon}
                onClick={e => {
                  contextMenuItem.onClick(e, asset);
                }}
              />
            );
          })}
      </div>
      {showDescription && <SubBody className={bem.element('description')}>{asset.description}</SubBody>}
    </ItemWrapper>
  );
};

const StandardImage: FC<{ image: string; blackWhite: boolean }> = ({ image, blackWhite }) => {
  return (
    <AssetImage
      imageSet={getImagePackSet({
        url: image,
        variations: [
          { width: 230, height: 129, breakpoint: Breakpoints.SM },
          { width: 296, height: 166, breakpoint: Breakpoints.MD },
          { width: 460, height: 258, breakpoint: Breakpoints.DEFAULT },
        ],
      })}
      alt=""
      blackWhite={blackWhite}
    />
  );
};

interface ImdbProps {
  imdbRating?: string;
  showImdbRating: 'onHover' | 'always' | 'never';
  alignRight?: boolean;
}
const pillBem = new CssBemHelper(bem.element('imdb-pill'));
const ImdbPill: FC<ImdbProps> = ({ imdbRating, showImdbRating, alignRight = false }) => {
  if (!imdbRating || showImdbRating === 'never') {
    return null;
  }
  return (
    <Pill
      className={classNames(pillBem.base, {
        [pillBem.modifier('show-on-hover')]: showImdbRating === 'onHover',
        [pillBem.modifier('right')]: alignRight,
      })}>
      <ImdbBadge imdbRating={imdbRating} />
    </Pill>
  );
};

const PriceTag = ({ price }: { price: string }) => {
  return <Tag className={'swimlane-standard-item_price-pill'}>{price}</Tag>;
};

const swimlaneConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 265,
    [Breakpoints.SM]: 410,
    [Breakpoints.MD]: 300,
    [Breakpoints.ML]: 275,
    [Breakpoints.LG]: 275,
    [Breakpoints.XL]: 300,
    [Breakpoints.XXL]: 315,
    [Breakpoints.DEFAULT]: 340,
  },
  breakpoints: {
    [Breakpoints.XS]: { slidesToShow: 1.35, slidesToScroll: 1 },
    [Breakpoints.SM]: { slidesToShow: 1.35, slidesToScroll: 1 },
    [Breakpoints.MD]: { slidesToShow: 3 },
    [Breakpoints.ML]: { slidesToShow: 3 },
    [Breakpoints.LG]: { slidesToShow: 4 },
    [Breakpoints.XL]: { slidesToShow: 5 },
    [Breakpoints.XXL]: { slidesToShow: 6 },
    [Breakpoints.DEFAULT]: { slidesToShow: 7 },
  },
};

function addScrollIfTextOverflow(refArray: React.RefObject<HTMLElement>[]) {
  refArray?.forEach(ref => {
    if (ref?.current) {
      const titleElement = ref.current;

      if (titleElement.scrollWidth > titleElement.offsetWidth) {
        titleElement.classList.add('scrollable');
      }
    }
  });
}

export { StandardItem, swimlaneConfig };
