import { Products } from '@strim/gateway-api';
import { formatISO, fromUnixTime } from 'date-fns';

import type { KnownExternalServiceId } from '@rikstv/play-common/src/forces/externalService/externalServiceIds';
import type { CommonAnalytics } from '@rikstv/play-common/src/utils/analytics/CommonAnalytics.types';
import {
  commonGoogleTagManager,
  EventCategory as CommonCategory,
  getTrack,
  InteractionEvent,
  push,
} from '@rikstv/play-common/src/utils/analytics/googleTagManager';

import { PackageKeys } from '../../domain/packages/forces/constants';
import type { BillingProviders } from '../../domain/payment/forces/payment.utils';
import { getProductsPriceAndDiscount, hackConstructOldPackageName } from '../../domain/products/utils/productHelpers';
import type { UserInfo } from '../../domain/user/forces/user.types';

import { paymentContextStorage } from './utils/analytics.paymentContextStorage';

type Category =
  | CommonCategory
  | (
      | 'trackElement'
      | 'trackEvent'
      | 'external_service_activate'
      | 'external_service_activated'
      | 'external_service_activation_aborted'
      | 'external_service_activation_failed'
      | 'external_service_activation_status'
      | 'payment_initiated'
      | 'payment_confirmed'
      | 'view_promotion'
      | 'select_promotion'
      // e-commerce
      | 'add_to_cart'
    );

const track = getTrack<Category>();

export const strimAnalytics = {
  ...commonGoogleTagManager,
  trackScreen: (path: string) => {
    commonGoogleTagManager.trackScreen(path);
    const lcPath = path.toLowerCase();
    if (lcPath.startsWith('/bli-kunde/strim_')) {
      paymentContextStorage.setSignUpPageLoadTime();
    }
  },
  userLoggedIn: (userInfo: UserInfo) => {
    const { userId, subscribedPackage, pendingDowngradeToPackageId, isInTrialPeriod, personalia, analytics } = userInfo;
    const packageId = subscribedPackage ? subscribedPackage.id : '';
    const subscriptionState = subscribedPackage ? subscribedPackage.state : '';
    push({
      uid: userId,
      userCreatedDate: personalia.createdDate ? formatISO(personalia.createdDate) : '',
      visitorLoginState: 'yes',
      userProducts: packageId,
      futurePackageId: pendingDowngradeToPackageId ?? '',
      subscriptionState,
      isInTrialPeriod,
      sha256_id: analytics.emailSha256,
    });
  },
  userLoggedOut: () => {
    push({
      visitorLoginState: 'no',
    });
  },

  userEnteredEmail: () => {
    track('trackEvent', {
      eventCategory: 'UX',
      eventAction: 'User entered email',
    });
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'register/entered-valid-email',
      },
    });
  },
  addToCart: (products: Products.Product[]) => {
    // reset current e-commerce flow
    // TODO: update to using e-com when checkout and remove from cart is also available
    track('trackEvent', {
      eventCategory: 'Subscription',
      eventAction: 'User selected package for purchase',
      eventLabel: hackConstructOldPackageName(products),
    });
    // const _items = products.map(toECommerceItem);
    // window.dataLayer.push({ ecommerce: null });
    // track('add_to_cart', {
    //   ecommerce: {
    //     value: items.reduce((acc, item) => acc + item.price - item.discount, 0),
    //     items: items,
    //   },
    // });
  },
  userStoppedSubscription: () => {
    track('trackEvent', {
      eventCategory: 'Subscription',
      eventAction: 'User stopped renewal',
    });
  },
  userRestartedSubscription: () => {
    track('trackEvent', {
      eventCategory: 'Subscription',
      eventAction: 'User restarted renewal',
    });
  },
  userAbortedPurchase: (packageId: string) => {
    track('trackEvent', {
      eventCategory: 'Payment',
      eventAction: 'User aborted purchase',
      eventLabel: packageId,
    });
  },
  userMakePurchase: (
    type: 'paypal' | 'card' | 'vipps',
    extra?: Omit<InteractionEvent['event_params'], 'event_group'>
  ) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: `payments/purchase/${type}`,
        ...extra,
      },
    });
  },
  userPurchaseValidationError: (
    type: 'paypal' | 'card' | 'vipps',
    extra?: Omit<InteractionEvent['event_params'], 'event_group'>
  ) => {
    push({
      event: 'user_interaction',
      event_params: {
        event_group: `payments/purchase-validation-error/${type}`,
        ...extra,
      },
    });
  },
  trackPurchaseConfirmed: (
    productsToBuy: Products.Product[] | { packageId: string; price: number },
    userInfo: UserInfo,
    isTrial: boolean,
    billingProvider: BillingProviders
  ) => {
    const gotSimpleObj = 'packageId' in productsToBuy;
    const packageId = gotSimpleObj ? productsToBuy.packageId : hackConstructOldPackageName(productsToBuy);
    const price = gotSimpleObj ? productsToBuy.price : getProductsPriceAndDiscount(productsToBuy).price;
    const customData = {
      eventCategory: 'Payment',
      eventAction: 'User confirmed payment for package',
      eventLabel: packageId,
      md5_id: userInfo.analytics.emailMd5,
      sha256_id: userInfo.analytics.emailSha256,
      sha256_idm: userInfo.analytics.phoneSha256,
      purchase_is_trial: isTrial,
      product_plan: packageId,
      billing_provider: billingProvider,
      user_created: formatISO(fromUnixTime(Date.now() / 1000)),
      user_id: userInfo.userId,
      ecommerce: {
        value: price,
      },
    };
    track('payment_confirmed', customData);

    const { paymentFlow, signUpFlow, secondsSinceSignUpLoadTime } = paymentContextStorage.get();
    if (paymentFlow && signUpFlow) {
      push({
        event: 'user_interaction',
        event_params: {
          event_group: `payments/success/${billingProvider.toLowerCase()}`,
          event_type: paymentFlow,
          context: signUpFlow,
          days_since_created: secondsSinceSignUpLoadTime,
          duration: secondsSinceSignUpLoadTime,
        },
      });
    }
  },
  userDowngradedToPackage: (productsToBuy: Products.Product[]) => {
    track('trackEvent', {
      eventCategory: 'Payment',
      eventAction: 'User downgraded package',
      eventLabel: hackConstructOldPackageName(productsToBuy),
    });
  },
  userUpgradedToPackage: (productsToBuy: Products.Product[]) => {
    track('trackEvent', {
      eventCategory: 'Payment',
      eventAction: 'User upgraded package',
      eventLabel: hackConstructOldPackageName(productsToBuy),
    });
  },
  userRegistrationCreateAccount: (signUpFlow: 'vipps' | 'email') => {
    paymentContextStorage.setSignUpAndPaymentFlow('new_subscription', signUpFlow);
    push({
      event: 'user_interaction',
      event_params: {
        event_group: 'register/create-account',
        event_type: signUpFlow,
      },
    });
  },
  userRegistrationSuccess: (flow: 'vipps' | 'email') => {
    track('trackEvent', {
      eventCategory: 'Signup',
      eventAction: 'User registration success',
    });
    const { secondsSinceSignUpLoadTime } = paymentContextStorage.get();
    push({
      event: 'user_interaction',
      event_params: {
        context: location.pathname,
        event_group: 'register/success',
        event_type: flow,
        days_since_created: secondsSinceSignUpLoadTime,
        duration: secondsSinceSignUpLoadTime,
      },
    });
  },
  externalServiceActivationStarted: (serviceId: KnownExternalServiceId) => {
    track('external_service_activate', {
      service: {
        name: serviceId,
      },
    });
  },
  externalServiceActivationCompleted: (serviceId: KnownExternalServiceId) => {
    track('external_service_activated', {
      service: {
        name: serviceId,
      },
    });
  },
  externalServiceActivationAborted: (serviceId: KnownExternalServiceId) => {
    track('external_service_activation_aborted', {
      service: {
        name: serviceId,
      },
    });
  },
  externalServiceActivationFailed: (serviceId: KnownExternalServiceId) => {
    track('external_service_activation_failed', {
      service: {
        name: serviceId,
      },
    });
  },
  trackViewWinbackPromotion: (
    percentageFactor: number,
    numberOfBillingCycles: number,
    packageId: PackageKeys,
    location: string
  ) => {
    track('view_promotion', {
      ecommerce: {
        creative_name: `${percentageFactor * 100}% ${numberOfBillingCycles} md`,
        promotion_id: `WB ${packageId}`,
        promotion_name: 'Strim WB',
        location_id: location,
      },
    });
  },
  trackSelectWinbackPromotion: (
    percentageFactor: number,
    numberOfBillingCycles: number,
    packageId: PackageKeys,
    location: string
  ) => {
    track('select_promotion', {
      ecommerce: {
        creative_name: `${percentageFactor * 100}% ${numberOfBillingCycles} md`,
        promotion_id: `WB ${packageId}`,
        promotion_name: 'Strim WB',
        location_id: location,
      },
    });
  },
  trackPaymentFlowType: (paymentFlowType: BuyPackageFlow) => {
    track('show_payment', {
      payment_flow: paymentFlowType,
    });
  },
} satisfies CommonAnalytics & Record<string, unknown>;

type BuyPackageFlow =
  | 'new_subscription'
  | 'reactivate'
  | 'reactivate_pckg_subscr_ended'
  | 'downgrade_package'
  | 'upgrade_package'
  | 'cancel_trial'
  | 'unchanged';
