import { FC, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';

import config from '@rikstv/play-common/src/config';

import { INCOMPLETE_LOADING_STATUSES } from '../../utils/types/typeUtils';
import { addParameters } from '../../utils/url/url.utils';
import { AssetNotFound } from '../errorPage/AssetNotFound';
import { getPrioritizedBanner, useFetchBanners } from '../infoBanner/TopBanner';
import { Section } from '../section/Section';
import { CoreScrollSwimlane } from '../swimlane/coreScrollSwimlane/CoreScrollSwimlane';
import { PaginatedSwimlane } from '../swimlane/PaginatedSwimlane';

import { getCanonicalUrl } from './internal/getCanonicalUrl';
import { PageHeader } from './internal/PageHeader';
import { usePageFromSlug } from './internal/usePage';
import { useSlug } from './internal/useSlug';
import { PageContext } from './PageContext';
import { PageFilterMenu } from './PageFilterMenu';
import { getMetaProperties } from './pageMetaProperties';
import { useEmptyCollectionReporting } from './useEmptyCollectionReporting';
const TopBanner = lazy(() => import('../infoBanner/TopBanner').then(module => ({ default: module.TopBanner })));

const EmptyMyContentHeader = lazy(() =>
  import('./internal/EmptyMyContentHeader').then(module => ({ default: module.EmptyMyContentHeader }))
);
const SwimlanesNotFound = lazy(() =>
  import('./internal/SwimlanesNotFound').then(module => ({ default: module.SwimlanesNotFound }))
);
const BroadcastSchedule = lazy(() =>
  import('../broadcastSchedule/BroadcastSchedule').then(module => ({ default: module.BroadcastSchedule }))
);
import './page.scss';

export const Page: FC = () => {
  const { slugFromPath, isCollection } = useSlug();
  const { page, loadingStatus } = usePageFromSlug(slugFromPath);
  const banners = useFetchBanners();
  // used to figure out if entire page has no content so we can report it
  // via a slack workflow
  const { reportSwimlaneItemCount } = useEmptyCollectionReporting(page);

  if (loadingStatus === 'FAILED') {
    return <AssetNotFound contextInfo={`page failed to load from slug=${slugFromPath}}`} />;
  }

  if (!page) {
    return null;
  }

  const wrapperClassName = classNames('common-page__swimlane-wrapper', {
    'common-page__swimlane-wrapper--no-img': !page.backgroundImage,
  });
  const nrOfSwimlanes = page.swimlanes.length;
  const hideSwimlaneTitle = nrOfSwimlanes === 1 && page.type !== 'exploration';
  const isSchedule = !!page.scheduleLink;

  const { slug, backgroundImage } = page;

  const meta = getMetaProperties(config.application, page);
  const pageLoadedWithNoSwimlanes = nrOfSwimlanes === 0 && !INCOMPLETE_LOADING_STATUSES.includes(loadingStatus);
  const topBanner = banners && page.title === 'Start' ? getPrioritizedBanner(banners) : undefined;

  return (
    <>
      <Helmet title={meta.title}>
        <link rel="canonical" href={getCanonicalUrl(slug, window.location.origin, isCollection)} />
        <meta property="og:url" content={getCanonicalUrl(slug, window.location.origin, isCollection)} />
        <meta property="og:title" content={`${meta.title} | ${config.application}`} />
        <meta name="title" content={`${meta.title} | ${config.application}`} />
        {meta.description && <meta property="og:description" content={meta.description} />}
        {meta.description && <meta name="description" content={meta.description} />}
        {backgroundImage && <meta property="og:image" content={addParameters(backgroundImage, { width: '1200' })} />}
      </Helmet>
      <div className="common-page">
        {topBanner && <TopBanner banner={topBanner} />}
        <Section type="transparent">
          {!pageLoadedWithNoSwimlanes && <PageHeader {...page} />}
          {pageLoadedWithNoSwimlanes && !isSchedule && <EmptySwimlanes slug={page.slug} />}
          <div className={wrapperClassName}>
            <PageContext.Provider value={{ hideSwimlaneTitle, title: page.title, slug: page.slug }}>
              {page.type === 'exploration' && <PageFilterMenu page={page} />}

              {page.scheduleLink && <BroadcastSchedule url={page.scheduleLink} title={page.title} />}
              {page.swimlanes.map((s, index) => {
                if (s.supportsPaging && s.style === 'SwimmingPool') {
                  return (
                    <PaginatedSwimlane
                      animate={page.type === 'exploration'}
                      swimlane={s}
                      hideSwimlaneTitle={hideSwimlaneTitle}
                      reportSwimlaneItems={reportSwimlaneItemCount}
                      key={s.id}
                    />
                  );
                }
                return (
                  <CoreScrollSwimlane
                    swimlane={s}
                    swimlaneIndex={index}
                    reportSwimlaneItems={reportSwimlaneItemCount}
                    key={s.id}
                  />
                );
              })}
            </PageContext.Provider>
          </div>
        </Section>
      </div>
    </>
  );
};

const EmptySwimlanes = ({ slug }: { slug: string }) => {
  if (slug.toLowerCase().startsWith('mitt-innhold')) {
    return <EmptyMyContentHeader />;
  }

  return <SwimlanesNotFound />;
};
