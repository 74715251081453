import { createSelector } from '@reduxjs/toolkit';

import type { KnownExternalServiceId } from '@rikstv/play-common/src/forces/externalService/externalServiceIds';
import { LooseStringUnion } from '@rikstv/play-common/src/utils/types/typeUtils';

import { routerPaths } from '../../../common/router/router.path';
import type { StrimState } from '../../../root.reducer';
import { selectUserStatusTV2PlayActivation } from '../../activateExternalService/forces/selectors';

import { UserInfo } from './user.types';

export const selectUserInfo = (state: StrimState): UserInfo | null | undefined => state.user.userInfo;

export const selectUserEmail = createSelector(selectUserInfo, userInfo => userInfo?.personalia.email);

export const selectUserPhoneNumber = createSelector(selectUserInfo, userInfo => userInfo?.personalia.phoneNumber);

export const selectSubscribedPackage = createSelector(selectUserInfo, userInfo => userInfo?.subscribedPackage);

export const selectHasContentAccess = createSelector(selectSubscribedPackage, pkg => pkg?.hasAccessToContent === true);

export const selectIsInTrialPeriod = createSelector(selectUserInfo, userInfo => !!userInfo?.isInTrialPeriod);

export const selectPostAuthRedirectUrl = (state: StrimState) => state.auth.postAuthRedirectUrl;

export const selectExternalServiceStatus = (id?: KnownExternalServiceId) => (state: StrimState) => {
  if (id) {
    return state.user.userInfo?.externalServicesStatus[id];
  }
};

export const selectHasExternalService = createSelector(
  selectExternalServiceStatus('viaplay'),
  selectExternalServiceStatus('tv2play'),
  selectExternalServiceStatus('max'),
  (viaplay, tv2play, max) => {
    return !!viaplay?.inSubscription || tv2play?.inSubscription || max?.inSubscription;
  }
);

// also check tv2 consent status
export const selectNotifications = (pathname: string) =>
  createSelector(
    selectUserInfo,
    selectExternalServiceStatus('viaplay'),
    selectExternalServiceStatus('tv2play'),
    selectExternalServiceStatus('max'),
    selectSubscribedPackage,
    selectUserStatusTV2PlayActivation,
    (userInfo, viaplay, tv2playStatus, maxStatus, subscribedPackage, userStatusTV2PlayActivation) => {
      const externalNotification: LooseStringUnion<KnownExternalServiceId>[] = [];

      if (
        [routerPaths.purchaseProducts, routerPaths.packagesNewReceipt, 'aktiver'].some(path => pathname.includes(path))
      ) {
        return { notifications: [], externalNotification };
      }

      const viaplayNotification =
        viaplay &&
        subscribedPackage?.hasAccessToContent &&
        viaplay.inSubscription &&
        viaplay.statusCode !== 'Completed';

      const tv2playNotification =
        tv2playStatus &&
        tv2playStatus.inSubscription &&
        tv2playStatus &&
        subscribedPackage?.hasAccessToContent &&
        tv2playStatus.statusCode !== 'Completed' &&
        userStatusTV2PlayActivation === 'READY';

      const maxNotification =
        maxStatus &&
        maxStatus.inSubscription &&
        subscribedPackage?.hasAccessToContent &&
        maxStatus?.statusCode !== 'Completed';

      if (viaplayNotification) {
        externalNotification.push('viaplay');
      }

      if (tv2playNotification) {
        externalNotification.push('tv2play');
      }

      if (maxNotification) {
        externalNotification.push('max');
      }

      const pastDueNotification = userInfo?.subscribedPackage?.state === 'PastDue' ? ['Betaling feilet'] : [];
      return {
        notifications: [...pastDueNotification, ...externalNotification],
        externalNotification,
      };
    }
  );
