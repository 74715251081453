import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultProviderVariant } from '../contentStack.types';

export const useModalPlayerLogic = (defaultProviderVariant: DefaultProviderVariant) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [assetId, setAssetId] = useState<string | undefined>(undefined);

  const onCloseModalPlayer = useCallback(async () => {
    // reset assetId to close player modal
    setAssetId(undefined);
    // trigger refetch of SuperCoverPage with updated variant selection and progress
    dispatchCloseEvent();
  }, []);

  /* deal with auto play */
  useEffect(() => {
    const url = new URL(window.location.origin + pathname + search);
    if (url.searchParams.has('autoPlay')) {
      if (defaultProviderVariant?.behavior === 'PlayAsset') {
        setAssetId(defaultProviderVariant.tracking.elementId);
      }
      // update url and remove autoPlay parameter
      url.searchParams.delete('autoPlay');
      navigate(`${url.pathname}${url.searchParams.toString()}`, { replace: true });
    }

    // empty dep-array since we only want to do this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { assetId, setAssetId, onCloseModalPlayer };
};

const MODAL_PLAYER_CLOSE = 'modal-player.close';
const dispatchCloseEvent = () => document.dispatchEvent(new CustomEvent(MODAL_PLAYER_CLOSE));
export const useOnModalPlayerClosed = (callback: () => void) => {
  useEffect(() => {
    // prevent passing custom event to callback
    const callbackWrapper = () => callback();
    document.addEventListener(MODAL_PLAYER_CLOSE, callbackWrapper);
    return () => document.removeEventListener(MODAL_PLAYER_CLOSE, callbackWrapper);
  }, [callback]);
};
