import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { loginSuccess, tokenRenewed } from '@rikstv/play-common/src/forces/auth/auth.slice';
import { loadUserInfo, userInfoLoaded } from '@rikstv/play-common/src/forces/loadUserInfo/loadUserInfo.slice';
import { hasSucceededLoadingUserInfo } from '@rikstv/play-common/src/forces/loadUserInfo/selectors';
import { callAuthenticated } from '@rikstv/play-common/src/forces/utils/callAuthenticated';
import history from '@rikstv/play-common/src/router/history';
import { isRendertron } from '@rikstv/play-common/src/utils/device/device.utils';
import { captureExceptionInSentry, errorTracker } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { AwaitedReturnType, isOneOf } from '@rikstv/play-common/src/utils/types/typeUtils';

import { getBillingProvider } from '../../../apis/StrimGatewayAPI';
import routerPath from '../../../common/router/router.path';
import { isEmailPage, isPaymentPage, isVippsReturnPage } from '../../../common/router/strimrouter.utils';
import { useTV2ActivationStore } from '../../activateExternalService/hooks/useTV2ActivationStore';

import api from './api';
import { logoutIfUserDeleted } from './logoutIfUserDeleted';
import { WAITING_STATES } from './packageStates';
import { signUpAndUpgradeSagas } from './sagas.signUpAndUpgrade';
import { authenticationSagas } from './sagasAuthentication';
import { selectSubscribedPackage } from './selectors';
import { userActions } from './userInfo.slice';

export function* getUserInfo() {
  try {
    const userInfo: AwaitedReturnType<typeof api.getUserInfo> = yield call(callAuthenticated, api.getUserInfo);
    errorTracker.setUserId(userInfo.userId);
    yield put(userActions.loadedUser(userInfo));
    yield put(userInfoLoaded({ userInfoLoadSuccess: true }));
    useTV2ActivationStore.getState().checkTV2ProvisionStatus(userInfo);
  } catch (err) {
    console.error(err);
    // HACK (tolu): we're logging people out regardless of error thrown? Why?
    yield put(userInfoLoaded({ userInfoLoadSuccess: false, error: err }));
    yield put(userActions.logoutWithReason('USER_INFO_FAILED'));
  }
}

function* redirectOnIncompletePaymentOrPackage() {
  const selectedPackage: ReturnType<typeof selectSubscribedPackage> = yield select(selectSubscribedPackage);
  const isOnPaymentPage = isPaymentPage(window.location);
  const isOnEmailPage = isEmailPage(window.location);
  const isOnPasswordResetPage = window.location.pathname.includes(routerPath.resetPassword());
  const isOnPhoneNumberPage = window.location.pathname.includes(routerPath.changePhoneNumber());

  // If user cancels or fails to return from Vipps payment for any reason
  // user will still be in "PaymentInProgress"/"UpgradeInProgress" and we will automatically return
  // the user to the returnUrl. We must fetch paymentInfo to check if the user
  // has selected Vipps as paymentMethod. For Braintree this is very much expected
  // to be an intermediate state, so no handling should be completely fine for Braintree
  if (selectedPackage && WAITING_STATES.includes(selectedPackage.state)) {
    try {
      const { billingProvider }: AwaitedReturnType<typeof getBillingProvider> =
        yield callAuthenticated(getBillingProvider);
      if (billingProvider === 'Vipps' && !isVippsReturnPage(window.location)) {
        return history.push(routerPath.vippsReturnUrl());
      }
    } catch (error) {
      captureExceptionInSentry(error);
    }
  }

  // log user out if deleted, occurs when cancelling "simplified sign-up flow"
  if (!isVippsReturnPage(window.location)) {
    yield callAuthenticated(token => logoutIfUserDeleted(token, '/'));
  }

  // If the user signs up, but doesn't pay => user is in pending payment state => redirect to payment page
  if (
    isOneOf(selectedPackage?.state, ['PendingPaymentInfo', 'PaymentFailed']) &&
    !isOnPaymentPage &&
    !isOnEmailPage &&
    !isOnPasswordResetPage &&
    !isOnPhoneNumberPage
  ) {
    history.push(routerPath.purchaseProducts(selectedPackage.selectedProductIds), { replace: true });
  }
}

function* postLoginSaga() {
  try {
    yield put(loadUserInfo());
    yield take(userInfoLoaded.type);
    const userInfoLoadSucceeded: ReturnType<typeof hasSucceededLoadingUserInfo> =
      yield select(hasSucceededLoadingUserInfo);
    if (userInfoLoadSucceeded) {
      yield call(redirectOnIncompletePaymentOrPackage);
    }
  } catch (err) {
    captureExceptionInSentry(err, { fingerprint: ['post-login-failed'] });
  }
}

function* userSagas() {
  if (isRendertron) {
    return;
  } else {
    yield fork(authenticationSagas);
    yield fork(signUpAndUpgradeSagas);
    yield all([
      takeLatest(loginSuccess.type, postLoginSaga),
      takeLatest([loadUserInfo.type, tokenRenewed.type], getUserInfo),
    ]);
  }
}

export default userSagas;
