export type ImagePackResizeGravity = 'north' | 'east' | 'south' | 'west';

interface ImagePackOptions {
  width?: number;
  height?: number;
  gravity?: ImagePackResizeGravity | null | undefined;
  ['resize-gravity']?: ImagePackResizeGravity | null | undefined;
}

type ImagePackSetVariation = ImagePackOptions & {
  breakpoint: number;
};
interface ImagePackSetOptions {
  url: string;
  variations: ImagePackSetVariation[];

  // global params for all image packs, may be overridden in image pack set variation
  width?: number;
  height?: number;
  gravity?: ImagePackResizeGravity;
}

export interface ImageSetEntry {
  url: string;
  breakpoint: number;
}

const quantizeSize = (desiredSize: number) => {
  if (desiredSize <= 150) {
    return 150;
  } else if (desiredSize <= 300) {
    return 300;
  } else if (desiredSize <= 460) {
    return 460;
  } else if (desiredSize <= 600) {
    return 600;
  } else if (desiredSize <= 800) {
    return 800;
  } else {
    return desiredSize;
  }
};

// change size params into quantized ones to improve caching around the site.
const getQuantizedOptions = (options: ImagePackOptions): ImagePackOptions => {
  const { height, width } = options;
  const quantizedOptions = {
    ...options,
  };

  if (width && height) {
    const quantizedWidth = quantizeSize(width);
    quantizedOptions.width = quantizedWidth;
    quantizedOptions.height = quantizedWidth * (height / width);
  } else if (width) {
    quantizedOptions.width = quantizeSize(width);
  } else if (height) {
    quantizedOptions.height = quantizeSize(height);
  }
  return quantizedOptions;
};

export const getImagePackSource = (
  imagePackSource?: string | null | undefined,
  options?: ImagePackOptions | null | undefined
) => {
  if (!imagePackSource) {
    return '';
  }
  const url = new URL(imagePackSource);

  url.searchParams.append('progressive', 'true');
  if (options) {
    Object.entries(getQuantizedOptions(options)).forEach(entry => {
      const key = entry[0] as keyof ImagePackOptions;
      const value = entry[1];

      if (value !== undefined) {
        if (key === 'gravity') {
          url.searchParams.append('resize-gravity', value);
        } else {
          url.searchParams.append(key, value);
        }
      }
    });
  }

  const imgUrl = optimizeSkyShowTimeLogo(url.toString());

  return imgUrl;
};

export const getImagePackSet = ({ variations, url, gravity, width, height }: ImagePackSetOptions): ImageSetEntry[] =>
  variations.map(variation => {
    const options = getQuantizedOptions({
      gravity: variation.gravity || gravity,
      height: variation.height || height,
      width: variation.width || width,
    });

    return {
      url: getImagePackSource(url, options),
      breakpoint: variation.breakpoint,
    };
  });

const imgServiceHost = 'imageservice.rikstv.no/static';
const showTimeLogoPath = '/logopack/SkyShowtime_square.svg';
export const optimizeSkyShowTimeLogo = (logoUrl: string, width?: number) => {
  const url = new URL(logoUrl);
  if (url.toString().toLowerCase().includes(showTimeLogoPath.toLowerCase())) {
    // Svg is 223KB => png in 64px is 2.1kb
    url.hostname = imgServiceHost;
    url.pathname = `/static${showTimeLogoPath}`;
    if (width) {
      url.searchParams.append('width', width.toString());
    }
    return url.toString();
  }
  return logoUrl;
};
