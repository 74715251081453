import { Products, SubscriptionsTypes } from '@strim/gateway-api';

import { webAppLinks } from '@rikstv/shared-strim-paths';

import { ChannelOrService, CHANNELS, SERVICES } from './data/productChannelsAndServices';

interface ProductEnrichmentBase {
  modalSellingPoints: string[];
  modalImage: string;
  cmsUrl: string;
  content: {
    channels: ChannelOrService[];
    services: ChannelOrService[];
  };
}
interface ProductEnrichmentAddon {
  modalSellingPoints?: never[];
  modalImage?: never;
  cmsUrl?: never;
  content: {
    channels: ChannelOrService[];
    services: ChannelOrService[];
  };
}
type ProductEnrichment = ProductEnrichmentBase | ProductEnrichmentAddon;

/**
 * Source of truth can be found here:
 * <https://admin.strim.no/product>
 */
export const productNameToEnrichmentKeyRecord = Object.freeze({
  StrimLitt: '814a2423-2985-4fd7-b460-68fa1e242161',
  StrimMye: '824a2423-2985-4fd7-b460-68fa1e242161',
  StrimMest: '834a2423-2985-4fd7-b460-68fa1e242161',
  MaxReklamefri: 'c4c4c4ab-2448-4146-bdaa-1536e2c21876',
  MaxUltimate: 'b0bf9837-ec9a-4e2f-8f22-8b619188752b',
  MaxSport: '800306e4-63cb-4fee-b791-e6ff37e03506',
  ViaplayTotal: '7bf18231-0c8b-49ad-ad9e-762deb2f2d7b',
  SportPremium: '3bfeb655-6186-4725-8844-2d03d6831c1f',
  SportBasis: '87f50cc7-92b7-49fc-839d-7fc72e528251',
  Sportskanaler: 'f87e7239-66e3-4263-8782-73630168cb22',
}) satisfies Record<string, Products.EnrichmentKey>;

const MAX_ADDONS: Products.EnrichmentKey[] = [
  productNameToEnrichmentKeyRecord.MaxReklamefri,
  productNameToEnrichmentKeyRecord.MaxUltimate,
];
const SPORT_ADDONS: Products.EnrichmentKey[] = [
  productNameToEnrichmentKeyRecord.MaxSport,
  productNameToEnrichmentKeyRecord.Sportskanaler,
  productNameToEnrichmentKeyRecord.SportBasis,
  productNameToEnrichmentKeyRecord.ViaplayTotal,
  productNameToEnrichmentKeyRecord.SportPremium,
];

export const isMaxAddon = (key: Products.EnrichmentKey) => MAX_ADDONS.includes(key);

export const isSportAddon = (key: Products.EnrichmentKey) => SPORT_ADDONS.includes(key);

// Reverse lookup from enrichment key to product name
export const enrichmentKeyToProductNameRecord = Object.fromEntries(
  Object.entries(productNameToEnrichmentKeyRecord).map(([k, v]) => [v, k])
) as Record<Products.EnrichmentKey, keyof typeof productNameToEnrichmentKeyRecord>;

/**
 * HACK: Map from enrichment key to old package id's
 */
export const enrichmentKeyToPackageIdMap = new Map<
  Products.EnrichmentKey,
  SubscriptionsTypes.Keys.BaseKeys | SubscriptionsTypes.Keys.AddonKeys | ''
>([
  [productNameToEnrichmentKeyRecord.StrimLitt, 'STRIM_MINI'],
  [productNameToEnrichmentKeyRecord.StrimMye, 'STRIM_FILM_OG_SERIER'],
  [productNameToEnrichmentKeyRecord.StrimMest, 'STRIM_FAMILIE'],
  [productNameToEnrichmentKeyRecord.MaxReklamefri, ''],
  [productNameToEnrichmentKeyRecord.MaxUltimate, ''],
  [productNameToEnrichmentKeyRecord.MaxSport, ''],
  [productNameToEnrichmentKeyRecord.ViaplayTotal, 'SPORT_2B'],
  [productNameToEnrichmentKeyRecord.SportPremium, 'SPORT_VIAPLAY_TOTAL'],
  [productNameToEnrichmentKeyRecord.SportBasis, 'SPORT'],
  [productNameToEnrichmentKeyRecord.Sportskanaler, 'SPORTSKANALER'],
]);

// Reverse lookup from enrichment key to product name
export const packageIdToEnrichmentKeyMap = new Map(
  Array.from(enrichmentKeyToPackageIdMap).map(([k, v]) => [v, k])
) as ReadonlyMap<SubscriptionsTypes.Keys.BaseKeys | SubscriptionsTypes.Keys.AddonKeys | '', Products.EnrichmentKey>;

/**
 * Enrichments for use in sales
 */
export const productEnrichmentMap = new Map<Products.EnrichmentKey, ProductEnrichment>([
  // BASE PRODUCTS
  [
    productNameToEnrichmentKeyRecord.StrimLitt,
    {
      modalSellingPoints: ['9 norske og internasjonale TV-kanaler', 'Ingen bindingstid'],
      modalImage:
        'https://cdn.sanity.io/images/bj7ifs6s/strim-prod/403224ec47b36913f51e6cdcca85aa8e8d60ea01-1756x984.gif?w=2000&fit=max&auto=format',
      cmsUrl: webAppLinks.subscriptionStrimLitt,
      content: {
        channels: CHANNELS.LittChannels,
        services: [],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.StrimMye,
    {
      modalSellingPoints: ['TV, film og serier fra 13 TV-kanaler og 5 strømmetjenester', 'Ingen bindingstid'],
      modalImage:
        'https://cdn.sanity.io/images/bj7ifs6s/strim-prod/52c4704bf8a70ad250e9f5f9eb0b7d1094178556-1756x984.gif?w=750&q=75&fit=clip&auto=format',
      cmsUrl: webAppLinks.subscriptionStrimMye,
      content: {
        channels: CHANNELS.MyeChannels,
        services: SERVICES.BASE_SERVICES.concat(SERVICES.TV2Basic),
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.StrimMest,
    {
      modalSellingPoints: [
        'TV, film og serier fra 31 TV-kanaler og 7 strømmetjenester',
        'Reklamefri TV 2 Play med BritBox og alt av film og serier',
        'Ingen bindingstid',
      ],
      modalImage:
        'https://cdn.sanity.io/images/bj7ifs6s/strim-prod/066d73dc9ab60e2589ff89de1687dc87cbadb971-1756x984.gif?w=750&q=75&fit=clip&auto=format',
      cmsUrl: webAppLinks.subscriptionStrimMest,
      content: {
        channels: CHANNELS.MestChannels,
        services: SERVICES.BASE_SERVICES.concat(SERVICES.TV2Standard, SERVICES.TV2BritBox, SERVICES.BBCNordicPlus),
      },
    },
  ],
  // MAX ADDONS
  [
    productNameToEnrichmentKeyRecord.MaxReklamefri,
    {
      content: {
        channels: [],
        services: [SERVICES.MaxStandard],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.MaxUltimate,
    {
      content: {
        channels: [],
        services: [SERVICES.MaxPremium],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.MaxSport,
    {
      content: {
        channels: [],
        services: [SERVICES.MaxSport],
      },
    },
  ],
  // SPORT ADDONS
  [
    productNameToEnrichmentKeyRecord.ViaplayTotal,
    {
      content: {
        channels: CHANNELS.SportChannels,
        services: [SERVICES.ViaplayPremium],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.SportPremium,
    {
      content: {
        channels: CHANNELS.SportChannels,
        services: [SERVICES.ViaplayPremium, SERVICES.TV2Premium],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.Sportskanaler,
    {
      content: {
        channels: CHANNELS.SportChannels,
        services: [],
      },
    },
  ],
  [
    productNameToEnrichmentKeyRecord.SportBasis,
    {
      content: {
        channels: CHANNELS.SportChannels,
        services: [SERVICES.TV2Premium],
      },
    },
  ],
]);
