import { jwtDecode } from 'jwt-decode';

export interface IToken {
  exp?: number;
  sub?: string;
  email?: string;
  'urn:rikstv:1:tag'?: string | string[];
  'urn:rikstv:1:cid'?: string;
  'http://rikstv.no/claims/customernumber/1'?: number;
  'urn:rikstv:2:channel'?: string[];
}

export const decodeToken = <T extends IToken>(token: string): T => {
  return jwtDecode<T>(token);
};
