// load polyfills first
// eslint-disable-next-line simple-import-sort/imports
import '@rikstv/play-common/src/utils/polyfills/polyfill';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { ErrorBoundaryAppContainer } from '@rikstv/play-common/src/components/errorBoundary/ErrorBoundaryAppContainer';
import config from '@rikstv/play-common/src/config';
import { initThirdPartyCookiesTester } from '@rikstv/play-common/src/utils/thirdPartyCookiesTester';
import { CastProvider } from '@rikstv/play-player/src/chromecast/CastProvider';
import { StrimProvider } from '@rikstv/shared-components';

import Router from './common/router/Router';
import store from './common/store/store';
import { bustOutOfActivationIframe } from './domain/activateExternalService/forces/externalService.utils';
import { checkForPreloadedVoucher } from './domain/voucher/voucher.utils';

import './index.css';

// If the app is loaded inside the external service activation iframe (inception!), this code will
// try to reroute the outer page to whatever page is requested inside the frame.
// This may happen for example if the activation form (on viaplay or similar) links to strim.no
bustOutOfActivationIframe();

checkForPreloadedVoucher();

initThirdPartyCookiesTester();

const rootElement = document.getElementById('root');
if (rootElement == null) {
  throw new Error('rootElement cannot be null');
}

const App = () => (
  <StrictMode>
    <ErrorBoundaryAppContainer>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>{config.basePageTitle}</title>
          <meta property="og:url" content={config.baseApplicationUrl} />
          <meta property="og:site_name" content={config.basePageTitle} />
          <meta property="og:image" content={`${config.baseApplicationUrl}/strimshare.jpg`} />
          <meta property="al:android:app_name" content={config.androidApp.appName} />
          <meta property="al:android:package" content={config.androidApp.package} />
          <meta property="al:ios:app_name" content={config.iosApp.appName} />
          <meta property="al:ios:app_store_id" content={config.iosApp.appStoreId} />
        </Helmet>
        <StrimProvider>
          <Provider store={store}>
            <CastProvider>
              <Router />
            </CastProvider>
          </Provider>
        </StrimProvider>
      </HelmetProvider>
    </ErrorBoundaryAppContainer>
  </StrictMode>
);

const root = createRoot(rootElement);
root.render(<App />);
